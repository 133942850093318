import React from 'react';
import { Link }  from 'gatsby'
import Otb from '../../svg/otb.js';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-primary shadow BorderColor">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center">
        <div className="w-64 mr-2">
          <Link to="/" aria-label="Home | On The Block Real Estate">
            <Otb alt="On The Block Real Estate International"/>
          </Link>
        </div>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <Link className="px-4 text-white font-light" to="/about-us">
          ABOUT
        </Link>
        <Link className="px-4 text-white font-light" to="/buyer">
          BUYING
        </Link>
        <Link className="px-4 text-white font-light" to="/seller">
          SELLING
        </Link>
        <Link className="px-4 text-white font-light" to="/join-us">
          JOIN
        </Link>
      </div>
      <Link className="hidden md:block" to="/contact">
        <Button className="text-sm">CONTACT</Button>
      </Link>
    </div>
  </header>
);

export default Header;
