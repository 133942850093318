import React from 'react';
import Exp from '../../images/on-the-block-brokered-by-exp.png'

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">On The Block Real Estate International concentrates 
        on Educating Buyers and Sellers of property to make the Right Financial 
        Choices for themselves and their families.</p>
        <img src={Exp} className="pt-3 w-32" alt="Brokered By Exp Realty Logo"/>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Company</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="/location">Location</a>
          </li>
          <li>
            <a href="/mortgage-info">Mortgage Info</a>
          </li>
          <li>
            <a href="/contact">Income Properties</a>
          </li>
          <li>
            <a href="/privacy">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.linkedin.com/company/on-the-block-real-estate-international">LinkedIn</a>
          </li>
          <li>
            <a href="https://www.instagram.com/ontheblockre/">Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/ontheblockrealestate/">Facebook</a>
          </li>
        </ul>
      </div>
    </div>
    <div className="pt-10 text-center">On The Block Real Estate, Inc. © 2015-2021 Los Alamitos, CA | <a href="tel:562-972-1390 ">(562)972-1390 </a></div>
    <div className="pt-3 text-muted text-center">Developed & Managed By <a href="https://www.endslate.co/" rel="noopener noreferrer nofollow" target="_blank">Endslate, Inc.</a></div>
  </footer>
);

export default Footer;
